;(function($){
'use strict';
/* ==========================================================================
 *
 * フォーム操作クラス
 *
 * global namespace: fabbit
 *
========================================================================== */
/* name space init
-------------------------------------*/
window.fabbit = window.fabbit || {};

/* constructor
---------------------------------------------- */
function formUtils(){
	let _ = this;

	// ファイル名のセット
	_.fileNameSet();
};

/* ----------------------------------------------
 * inputタグのfileの変更時にネームをセット
 * @ :
 * return :
---------------------------------------------- */
formUtils.prototype.fileNameSet = function(){
	let _ = this;

	$('form').on('change','input[type="file"]',function(){
		const $wrap = $(this).parents('*[data-form-input="file"]');

		const file = $(this).prop('files')[0];

		$wrap.find('span').text(file.name);
	});
};


/* ==========================================================================
 * プロジェクトインスタンス生成
========================================================================== */
fabbit.form = $.extend( true ,new formUtils(), fabbit.form );

})(jQuery);
