;(function($){
'use strict';
/* ==========================================================================
 *
 * タブユーザーインターフェースクラス
 *
 * global namespace: fabbit
 *
========================================================================== */
/* name space init
-------------------------------------*/
window.fabbit = window.fabbit || {};

/* constructor
---------------------------------------------- */
function tabUI(){
	let _ = this;

	// オプション
	_.config = {
		exec: true,

		prefix: 'build-',
		targetAttribute: 'tab',
		groupAttribute: 'tab-group',

		css: `
<style>
.js-tab__content{
position: relative;
z-index: 1;
overflow: hidden;

opacity: 1;
transform: translate(0, 0);
transition: all 0.25s ease-in-out;
}
.js-tab__content[aria-hidden="true"]{
max-height: 0;
opacity: 0;
transform: translate(0, -5%);
}
</style>
		`,

	};

	_.init();
};

/* ----------------------------------------------
 * URLパーサ
 * @ :
 * return :
---------------------------------------------- */
tabUI.prototype.init = function(){
	let _ = this;

	//タブ処理
	$(document).on('ready',function(){
		if(!_.config.exec)
			return;

		$('head').append(_.config.css);

		const groupAttr = _.config.prefix + _.config.groupAttribute;

		// 対象要素を取得
		const $elms = $('*[' + groupAttr + ']');

		// 要素をグルーピング
		let tabGroups = [];
		$elms.each(function(i){
			if(tabGroups.indexOf($(this).attr(groupAttr)) == -1){
				tabGroups.push($(this).attr(groupAttr));
			}
		});

		// 全対象にタブ実装
		$.each(tabGroups,function(i){
			const $targets = $elms.filter('*[' + groupAttr + '="' + this + '"]');
			_.start($targets);
		});
	});
};

/* ディレクトリパーサ
 * @string:
 * return array = hash strings array
---------------------------------------------- */
tabUI.prototype.start = function($group){
	let _ = this;

	const $triggers = $group.filter('*[' + _.config.prefix + _.config.targetAttribute + '="trigger"]');
	const $targets = $group.not($triggers);

	// イベント設定
	$triggers.each(function(i){
		let item = {};

		item.trigger = $(this);
		item.target = $targets.filter('*[' + _.config.prefix + _.config.targetAttribute + '="' + $(this).attr('aria-controls') + '"]');

		// ターゲット要素がなければ離脱
		if(!item.target[0]){
			console.log('overall js error:タブトリガーに対応したターゲット要素が見つかりません。');
			return;
		}

		// ariaが展開ステートでなければターゲット要素を隠蔽
		if(item.trigger.attr('aria-selected') === 'true') {
			item.target.attr('aria-hidden',false);
		}else{
			item.trigger.attr('aria-selected',false);

			item.target.attr('aria-hidden',true);
		}

		// イベント設定
		item.trigger.on('click',function(e){
			e.preventDefault();

			$triggers.not(item.trigger).attr('aria-selected',false);
			item.trigger.attr('aria-selected',true);

			$targets.not(item.target).attr('aria-hidden',true);
			item.target.attr('aria-hidden',false);
		});
	});
};

/* ==========================================================================
 * プロジェクトインスタンス生成
========================================================================== */
fabbit.tabUI = $.extend( true ,new tabUI(), fabbit.tabUI );

})(jQuery);
