;(function($) {
/* ==========================================================================
 * custom theme template
 *
 * general scripts
 *
/* name space init
-------------------------------------*/
window.fabbit = window.fabbit || {};

/* work timing instant
----------------------- */
$(function(){

/* sample
---------------------------------------------- */
(function (){
// ここに処理を書く
})();

});
/* work timing instant */


/* work timing ready
----------------------- */
$(window).on('load',function(){

/* izimodal
---------------------------------------------- */
if ($('.izimodal')[0]) {

	// Primary modal
	$(document).on('click', '.modal-trigger', function (e){
		$('.izimodal').iziModal();
		e.preventDefault();
		const target = $(this).attr('data-target');
		$(target).iziModal('open');
	});

	// Time table modal
	$(document).on('click', '.time-table button[data-status=0]', function (e){
		$('.izimodal').iziModal();
		e.preventDefault();
		var modal = $('#time-table-modal'),
			room = $( this ).data( 'room' ),
			date = $( this ).data( 'date' ),
			time = $( this ).data( 'time' );
		modal.iziModal('open');

		modal.find( 'span[data-modal-room]' ).attr( 'data-modal-room', room ).text( room );
		modal.find( 'span[data-modal-date]' ).attr( 'data-modal-date', date ).text( date );
		modal.find( 'span[data-modal-time]' ).attr( 'data-modal-time', time ).text( time );
	});

	// Room modal
	$(document).on('click', '.js--click-modal-room', function (e){
		$('.izimodal').iziModal();
		e.preventDefault();
		var roomModal = $('#room-modal');
		roomModal.iziModal('open');
	});

	// Resisters
	$(document).on('click', '.js--click-modal-matching-register', function (e){
		$('.izimodal').iziModal();
		e.preventDefault();
		const target = $(this).closest('.js--target-modal-matching-register')
		const avator = target.find('.js--target-modal-matching-register-avator').attr('data-modal-matching-register-avator')
		const name = target.find('.js--target-modal-matching-register-name').attr('data-modal-matching-register-name')
		const text = target.find('.js--target-modal-matching-register-text').attr('data-modal-matching-register-text')
		const media = target.find('.js--target-modal-matching-register-media').attr('data-modal-matching-register-media')
		let html = '<div class="lightbox__table">'
		html += '<div class="lightbox__table-cell lightbox__table-cell--data">'
    html += '<img src="'+avator+'" class="border-radius object-fit-cover">'
		html += '</div>'
		html += '<div class="lightbox__table-cell lightbox__table-cell--head">'
    html += '<p class="">'+name+'</p>'
		html += '</div>'
		html += '</div>'

		html += '<p class="m-top--s">'+text+'</p>'
		html += '<p class="m-top--2s"><img src="'+media+'" class="max-h20vh"></p>'
		$('#matching-register-modal-html').html(html)
		var matchingRegisterModal = $('#matching-register-modal');
		matchingRegisterModal.iziModal('open');
	});


	// Close all modal
	$(document).on('click', '.lightbox-close', function (e){
		e.preventDefault();
		$(this).parents('.izimodal').iziModal('close');
	});


	// Auth modal
	if ($('#auth-modal')[0]) {
		$('#auth-modal.izimodal').iziModal({
			overlayClose: false
		});
		var authModal = $('#auth-modal');
		authModal.iziModal('open');
	}
	$(document).on('click', '.lightbox-auth-close', function (e){
		e.preventDefault();
		$('#auth-modal').iziModal('close');
	});

};/* end if */


});/* work timing ready */


/* work timing load
----------------------- */
$(window).on('load',function(){

/* sample
---------------------------------------------- */
if ($('.seelctor')[0]) {
(function (){
// ここに処理を書く

})();
};/* end if */

/* scrollTop
---------------------------------------------- */
if ($('#scrollToTopButton')[0]) {
	$('#scrollToTopButton').on('click',function(){
    $('body, html').animate({scrollTop: 0}, 300, 'linear');
	});
};/* work timing load */


/* アコーディオン
---------------------------------------------- */
if($('.js--click-accordion')[0]){
	$('.js--click-accordion').on('click',function(){
		var accordionTarget = $(this).attr('data-click-accordion');
		if($('[data-target-accordion="'+accordionTarget+'"]').length){
			$('[data-target-accordion="'+accordionTarget+'"]').toggle();
			$(this).toggleClass('active');
		}
	});
}

if($('.js--click-accordion-form')[0]){
	$('.js--click-accordion-form').on('click',function(){
		var accordionTarget = $(this).attr('data-click-accordion');
		if($('[data-target-accordion="'+accordionTarget+'"]').length){
			$('[data-target-accordion="'+accordionTarget+'"]').toggleClass('active');
			$(this).toggleClass('active');
		}
	});
}

/* 一括チェック
---------------------------------------------- */
if($('.js--click-check-all')[0]){
	$('.js--click-check-all').on('change',function(){
		if($(this).is(':checked')){
			$.each($('input[type="checkbox"].js--target-check-all'),function(i,v){
				$(v).prop("checked",true);
			});
		}else{
			$.each($('input[type="checkbox"].js--target-check-all'),function(i,v){
				$(v).prop("checked",false);
			});
		}
	})
}

/* toggle
---------------------------------------------- */
if($('.js--select-toggle')[0]){
	selectToggle();
	$('.js--select-toggle').on('change',function(){
		selectToggle(this);
	});
	function selectToggle(element){
		let selectId = '';
		let selectSubId = '';
		const selectName = $(element).attr('data-select-name')
		const selectDepth = $(element).attr('data-select-depth');
		//1階層目のみ、要素の表示・非表示を行う
		switch(Number(selectDepth)){
			case 0:
				$('.js--select-target[data-target-depth="'+selectDepth+'"]').hide();
			break;
		}
		//option要素からユニークなデータを判断する
		$(element).children('option').each(function(i,e){
			if($(e).prop("selected") === false){return;}
			if(typeof $(e).attr('data-select-id') === 'undefined'){return}
			selectId = $(e).attr('data-select-id')
			if(typeof $(e).attr('data-select-sub-id') === 'undefined'){return false;}
			selectSubId = $(e).attr('data-select-sub-id')
			return false;
		});
		$('[data-target-id="'+selectId+'"][data-target-name="'+selectName+'"]').show();
		//サブIDより、要素の表示・非表示を行う
		if(selectSubId !== ''){
			$('.js--select-target[data-target-sub-id][data-target-name="'+selectName+'"]').hide();
			$('.js--select-target[data-target-sub-id="'+selectSubId+'"][data-target-name="'+selectName+'"]').show();
		}else{
			$('.js--select-target[data-target-sub-id][data-target-name="'+selectName+'"]').hide();
		}
	}
}

if($('.js--radio-toggle')[0]){
	radioToggle();
	$('.js--radio-toggle').on('change',function(){
		radioToggle();
	});
	function radioToggle(){
		var selectTarget = $('.js--radio-toggle').attr('data-radio-toggle');
		$('.js--radio-target').hide();
		if($('.js--radio-toggle').prop("checked") === false){return;}
		if(typeof $('.js--radio-toggle').attr('data-radio-toggle') === 'undefined'){return;}
		$('[data-radio-toggle="'+selectTarget+'"]').show();
	}
}

/* object-fit polyfill
---------------------------------------------- */
if ($('.object-fit-cover')[0]) {
	objectFitImages();
}

if ($('.slider__item')[0]) {
	objectFitImages();
}

/* search clear
---------------------------------------------- */
if ($('.js--search-clear')[0]) {
	$('.js--search-clear').click(function(){
		$('input').each(function(){
			//checkboxまたはradioボタンの時
			if(this.type == 'checkbox'){
				//一括でチェックを外す
					this.checked = false;
			}
		});
	});
}

});/* work timing load */

/* Slider
---------------------------------------------- */
$( window ).on( 'load', function() {
	var sliders = $( '.slider' );
	sliders.each( function() {
		var slider = $(this);
		var sliderThumb = slider.clone();
		sliderThumb.removeClass( 'slider' ).addClass( 'slider-thumb' );
		sliderThumb.insertAfter(slider);
		slider.slick( {
			infinite: false
		} );
		sliderThumb.slick( {
			infinite: false,
			arrows: false,
			slidesToShow: 10
		} );

		slider.on( 'afterChange', function( event, slick, currentSlide ) {
			sliderThumb.slick('slickGoTo', currentSlide);
		} );

		sliderThumb.on('click', '.slick-slide', function(event) {
			event.preventDefault();
			var goToSingleSlide = $(this).data('slick-index');

			slider.slick('slickGoTo', goToSingleSlide);
		});
	} );
} );

/* SP Menu Expand
---------------------------------------------- */
$( window ).on( 'load', function() {
	$( document ).on( 'click', '.sp-open-panel', function( e ) {
		e.preventDefault();

		$( '.header-menu' ).addClass( 'open' );
		$( '.toggle-panel__overlay' ).addClass( 'open' );
	} );
	$( document ).on( 'click', '.toggle-panel__close', function( e ) {
		e.preventDefault();

		$( '.header-menu' ).removeClass( 'open' );
		$( '.toggle-panel__overlay' ).removeClass( 'open' );
	} );
	$( document ).on( 'click', '.close-sidebar', function( e ) {
		e.preventDefault();
		$( '.header-menu' ).removeClass( 'open' );
		$( '.toggle-panel__overlay' ).removeClass( 'open' );
	} );
} );

/* Sidebar Expand
---------------------------------------------- */
$( window ).on( 'load', function() {
	$( document ).on( 'click', '.sidebar-right.sidebar-accordion > section > h3', function( e ) {
		e.preventDefault();

		$( this ).parent().toggleClass( 'open' );
	} );
} );

/* Sidebar Panel SP
---------------------------------------------- */
$( window ).on( 'load', function() {
	$( document ).on( 'click', '.trigger-sidebar-panel', function( e ) {
		e.preventDefault();

		$( '.chat-sidebar-panel' ).addClass( 'open' );
		$( '.sub-header-menu__overlayer' ).addClass( 'open' );
	} );
	$( document ).on( 'click', '.close-sidebar', function( e ) {
		e.preventDefault();

		$( '.chat-sidebar-panel' ).removeClass( 'open' );
		$( '.sub-header-menu__overlayer' ).removeClass( 'open' );
	} );
	$( document ).on( 'click', '.i-amphtml-accordion-header', function( e ) {
		e.preventDefault();

		if ( $( this ).attr( 'aria-expanded' ) === 'true' ) {
			$( this ).attr( 'aria-expanded', 'false' );
		} else {
			$( this ).attr( 'aria-expanded', 'true' );
		}

		$( this ).parent().toggleClass( 'open' );
	} );
} );

/* sticky
---------------------------------------------- */
// position: stickyがブラウザで使えるかチェックするための関数
function detectSticky() {
  const div = document.createElement('div');
  div.style.position = 'sticky';
  return div.style.position.indexOf('sticky') !== -1;
}

//配置位置の設定
function callStickyState() {
	stickybits(document.querySelectorAll('.sticky'), {
		useStickyClasses: true,
		verticalPosition: 'bottom',
		noStyles: true
	});
	stickybits(document.querySelectorAll('.sub-header'), {
		useStickyClasses: true,
		verticalPosition: 'top',
		noStyles: true
	});
}

//スクロール方向に応じてクラス付与
function scrollSticky() {
	if(typeof $('.js-stickybit-parent')[0] === 'undefined'){return;}
	var position = $('.js-stickybit-parent').offset();
	$parent_position = $(window).scrollTop() - position.top;
	if(typeof $('.sub-header')[0] === 'undefined'){
		if ($parent_position < 360) {
				$('.js-stickybit-parent').addClass("is--pos-fix");
				$('.js-stickybit-parent').removeClass("is--pos-rel");
		} else {
				$('.js-stickybit-parent').removeClass("is--pos-fix");
				$('.js-stickybit-parent').addClass("is--pos-rel");
		}
	}else{
		if ($parent_position < 180) {
				$('.js-stickybit-parent').addClass("is--pos-fix");
				$('.js-stickybit-parent').removeClass("is--pos-rel");
		} else {
				$('.js-stickybit-parent').removeClass("is--pos-fix");
				$('.js-stickybit-parent').addClass("is--pos-rel");
		}
	}
}

//windowの高さを取得
function getWindowHeightSize(){
	return window.outerHeight;
}

//要素の高さを取得
function getElementHeightSize(){
	return document.querySelector('.section-wrap').offsetHeight;
}

//実行関数
function sticky(){
	//stickyがサポートされていれば返す
	if (detectSticky()) {return;}
	//指定要素の高さがwindowより低ければ返す
	if(getElementHeightSize() < getWindowHeightSize()){return;}

	callStickyState();
	if(typeof $('.js-stickybit-parent')[0] === 'undefined'){return;}
	if(typeof $('.sub-header')[0] === 'undefined'){
		$('.sidebar-l').addClass('sticky--top')
	}
	$(window).scroll(scrollSticky());
}

sticky()

})(jQuery);