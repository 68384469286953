;(function($){
'use strict';
/* ==========================================================================
 *
 * スムーススクロールクラス
 *
 * global namespace: fabbit
 *
========================================================================== */
/* name space init
-------------------------------------*/
window.fabbit = window.fabbit || {};

/* constructor
---------------------------------------------- */
function smooth(){
	let _ = this;

	// ロケーション情報
	_.config = {
		exec: true,
		prefix: 'build-',
		property: 'smooth',

		headerSelector: '.header',

		duration: 600,
		easing: 'swing',
	};

	_.init();
};

/* ----------------------------------------------
 * init
 * @ :
 * return :
---------------------------------------------- */
smooth.prototype.init = function(){
	let _ = this;

	_.start();
};

/* start
 * @string:
 * return array = hash strings array
---------------------------------------------- */
smooth.prototype.start = function(){
	let _ = this;

	let $header = null,
		headerHeight = 0;

	//固定ヘッダーの高さを取得
	$header = $(_.config.headerSelector);
	if($header[0]){
		headerHeight = $header.outerHeight();
	}else{
		headerHeight = 0;
	};

	// スクロールイベントセット
	$(document).on('click','a[href^="#"]',function(e){
		e.preventDefault();
		if($(this).is('[' + _.config.prefix + _.config.property + '="false"]'))
			return;

		if(location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname && this.hash.replace(/#/, '')){
			scrollStart(this.hash);
		};
	});

	// 他のページからのアンカーアクセスがあった場合
	$(window).on('load',function(){
		const hash = window.location.hash;

		scrollStart(hash);
	});

	function scrollStart(target){
		let $target;
		if (target === '#pagetop'){
			$target = $('body');
		}else{
			$target = $(target)[0] ? $(target) : false;
		}

		if($target){
			const targetOffset = $target.offset().top - headerHeight;

			$('html, body').animate({
				scrollTop: targetOffset
			}, _.config.duration, _.config.easing);
		};
	};
};


/* ==========================================================================
 * プロジェクトインスタンス生成
========================================================================== */
fabbit.scrollSmooth = $.extend( true ,new smooth(), fabbit.scrollSmooth );

})(jQuery);
