;(function($){
'use strict';
/* ==========================================================================
 *
 * タブユーザーインターフェースクラス
 *
 * global namespace: fabbit
 *
========================================================================== */
/* name space init
-------------------------------------*/
window.fabbit = window.fabbit || {};

/* constructor
---------------------------------------------- */
function togglePanel(){
	let _ = this;

	// 要素配列
	_.elms = [];

	// オプション
	_.config = {
		exec: true,
		scrollLock: true,

		prefix: 'build-',
		targetAttribute: 'toggle-panel',

		targetClass: 'toggle-panel',
	};

	_.init();
};

/* ----------------------------------------------
 * init
 * @ :
 * return :
---------------------------------------------- */
togglePanel.prototype.init = function(){
	let _ = this;

	_.start();
};

/* start
 * @string:
 * return array = hash strings array
---------------------------------------------- */
togglePanel.prototype.start = function(){
	let _ = this;

	$(document).on('ready',function(){
		// 必要スタイルの付与
		if(_.config.scrollLock)
			_.setStyle();

		// 要素を確保
		_.correctElms();

		// イベントセット
		_.eventSet();
	});
};

/* ----------------------------------------------
 * correctElms
 * @ :
 * return :
---------------------------------------------- */
togglePanel.prototype.correctElms = function(){
	let _ = this;

	// トリガー要素を全てピックアップ
	$('*['+ _.config.prefix + _.config.targetAttribute +'="trigger"]').each(function(){
		let elm = {};

		// トリガーを格納
		elm.trigger = $(this);

		// ターゲットを格納
		let targetPanel = elm.trigger.attr('aria-controls');
		elm.panel = $('*['+ _.config.prefix + _.config.targetAttribute + '="' + targetPanel +'"]');

		// expand属性を付与
		elm.trigger.attr('aria-expanded', false);
		elm.panel.attr('aria-hidden', true);

		elm.panel.after($('<div class="'+_.config.targetClass+'__overlay"></div>'));

		// パネルが存在すれば格納
		if(elm.panel[0]){
			_.elms.push(elm);
		}
	});
};

/* eventSet
* @string:
* return:
---------------------------------------------- */
togglePanel.prototype.eventSet = function (elm) {
	let _ = this;

	$.each(_.elms,function(i){
		let elm = this;

		// イベントセット
		elm.trigger.on('click',function(e){
			e.preventDefault();

			// 閉じる
			if(elm.trigger.attr('aria-expanded') === 'true'){
				_.close(elm);

			}else{
			// 開く
				_.open(elm);
			};
		});

		// クローズボタンが押された場合閉じる
		elm.panel.on('click', '*[' + _.config.prefix + _.config.targetAttribute + '="close"]',function(e){
			e.preventDefault();

			// 非展開時の操作
			_.close(elm);
		});

		// オーバーレイが押された場合閉じる
		elm.panel.next('.toggle-panel__overlay').on('click',function(e){
			e.preventDefault();

			// 非展開時の操作
			_.close(elm);
		});
	});
};

/* open
* @string:
* return:
---------------------------------------------- */
togglePanel.prototype.open = function (elm) {
	let _ = this;

	// パネルのクラスと属性を切り替え
	elm.panel.attr('aria-hidden', false);

	// aria属性を反映
	elm.trigger.attr('aria-expanded', true);

	if (_.config.scrollLock) {
		$('body').addClass('scrollLock');
		$('body').css('top', -1 * this.scrollLockTop);
	};
};

/* close
* @string:
* return:
---------------------------------------------- */
togglePanel.prototype.close = function (elm) {
	let _ = this;

	// パネルのクラスと属性を切り替え
	elm.panel.attr('aria-hidden', true);

	// aria属性を反映
	elm.trigger.attr('aria-expanded', false);

	if (_.config.scrollLock) {
		$('body').removeClass('scrollLock');
		$('html, body').prop({ scrollTop: this.scrollLockTop });
	};
};


/* setStyle
* @string:
* return:
---------------------------------------------- */
togglePanel.prototype.setStyle = function () {
	let _ = this;

	// 必要スタイルの追記
	const scrollLockStyle = `
<style>
.scrollLock{
	position: fixed;
	width: 100%;
}
</style>
			`;
	$('head').append(scrollLockStyle);
};


/* ==========================================================================
 * プロジェクトインスタンス生成
========================================================================== */
window.fabbit.togglePanel = $.extend( true ,new togglePanel(), window.fabbit.togglePanel );

})(jQuery);
